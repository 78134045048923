import { ajax } from '@/js/extension'

const BASE_URL = '/gateway/openapi-panda'

const OpenApi = {
  sendLead (input, success, failure) {
    return ajax.post(BASE_URL + '/loan/create', input, success, failure)
  }
}

export { OpenApi }
